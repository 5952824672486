export const routes = [
    {
        path: '/users',
        name: 'users.browse',
        component: () => import(/* webpackChunkName: "UsersBrowse" */ '@/views/app/UsersBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/users/:id/clone',
        name: 'users.clone',
        component: () => import(/* webpackChunkName: "UsersActions" */ '@/views/app/UsersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/users/create',
        name: 'users.create',
        component: () => import(/* webpackChunkName: "UsersActions" */ '@/views/app/UsersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/users/:id/edit',
        name: 'users.edit',
        component: () => import(/* webpackChunkName: "UsersActions" */ '@/views/app/UsersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/users/:id/delete',
        name: 'users.delete',
        component: () => import(/* webpackChunkName: "UsersActions" */ '@/views/app/UsersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/users/:id/show',
        name: 'users.show',
        component: () => import(/* webpackChunkName: "UsersActions" */ '@/views/app/UsersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]